/* AIShortVideoShowcase.module.css */

/* Neon Background Styles */
.neonBackground {
  background: #050213;
  /* min-height: 100vh; */
  padding-top: 120px;
  padding-bottom: 120px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  isolation: isolate;
}

.lavaBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(50px);
  opacity: 0.95;
  transform: scale(1.2);
}

.lavaBackground::before,
.lavaBackground::after,
.lavaBackground span {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  left: -25%;
  top: -25%;
}

.lavaBackground::before {
  background: radial-gradient(
    circle at center,
    rgba(255, 0, 89, 1) 0%,
    rgba(var(--color2), 0.8) 25%,
    rgba(89, 0, 255, 0.6) 45%,
    rgba(3, 1, 8, 0.9) 70%
  );
  animation: blobMove 12s ease-in-out infinite alternate;
}

.lavaBackground::after {
  background: radial-gradient(
    circle at center,
    rgba(0, 217, 255, 1) 0%,
    rgba(var(--color4), 0.8) 25%,
    rgba(255, 0, 200, 0.6) 45%,
    rgba(3, 1, 8, 0.9) 70%
  );
  animation: blobMove2 10s ease-in-out infinite alternate;
}

.lavaBackground span {
  background: radial-gradient(
    circle at center,
    rgba(0, 255, 106, 1) 0%,
    rgba(var(--color-interactive), 0.8) 25%,
    rgba(255, 0, 76, 0.6) 45%,
    rgba(3, 1, 8, 0.9) 70%
  );
  animation: blobMove3 11s ease-in-out infinite alternate;
}

/* Add a new overlay for extra effects */
.glowingOverlay {
  position: absolute;
  top: -150%;
  left: -150%;
  width: 400%;
  height: 400%;
  background: radial-gradient(circle at center, rgba(255, 0, 89, 0.2) 0%, /* Pink pulse */ transparent 20%);
  animation: wanderingPulse 40s ease-in-out infinite;
  mix-blend-mode: screen;
}

/* Second pulse (Magenta) */
.glowingOverlay::before {
  content: '';
  position: absolute;
  top: -150%;
  left: -150%;
  width: 400%;
  height: 400%;
  background: radial-gradient(circle at center, rgba(255, 0, 255, 0.2) 0%, transparent 20%);
  animation: wanderingPulse3 45s ease-in-out infinite;
  mix-blend-mode: screen;
}

/* Third pulse (Neon Green) */
.glowingOverlay::after {
  content: '';
  position: absolute;
  top: -150%;
  left: -150%;
  width: 400%;
  height: 400%;
  background: radial-gradient(circle at center, rgba(0, 255, 106, 0.2) 0%, transparent 20%);
  animation: wanderingPulse2 50s ease-in-out infinite;
  mix-blend-mode: screen;
}

/* Fourth pulse (Golden Yellow) */
.glowingOverlay span {
  position: absolute;
  top: -150%;
  left: -150%;
  width: 400%;
  height: 400%;
  background: radial-gradient(circle at center, rgba(255, 217, 0, 0.2) 0%, transparent 20%);
  animation: wanderingPulse4 55s ease-in-out infinite;
  mix-blend-mode: screen;
  display: block;
}

/* Different paths for each pulse */
@keyframes wanderingPulse {
  0% {
    opacity: 0.4;
    transform: scale(1) translate(0%, 0%);
  }
  20% {
    opacity: 0.6;
    transform: scale(1.1) translate(10%, -8%);
  }
  40% {
    opacity: 0.5;
    transform: scale(1.05) translate(-10%, 12%);
  }
  60% {
    opacity: 0.7;
    transform: scale(1.15) translate(-12%, -10%);
  }
  80% {
    opacity: 0.5;
    transform: scale(1.1) translate(8%, 10%);
  }
  100% {
    opacity: 0.4;
    transform: scale(1) translate(0%, 0%);
  }
}

@keyframes wanderingPulse2 {
  0% {
    opacity: 0.3;
    transform: scale(1) translate(0%, 0%);
  }
  25% {
    opacity: 0.5;
    transform: scale(1.1) translate(-15%, 10%);
  }
  50% {
    opacity: 0.4;
    transform: scale(1.05) translate(15%, 12%);
  }
  75% {
    opacity: 0.6;
    transform: scale(1.15) translate(10%, -15%);
  }
  100% {
    opacity: 0.3;
    transform: scale(1) translate(0%, 0%);
  }
}

@keyframes wanderingPulse3 {
  0% {
    opacity: 0.3;
    transform: scale(1) translate(0%, 0%);
  }
  25% {
    opacity: 0.5;
    transform: scale(1.1) translate(12%, -15%);
  }
  50% {
    opacity: 0.4;
    transform: scale(1.05) translate(-18%, -12%);
  }
  75% {
    opacity: 0.6;
    transform: scale(1.15) translate(-8%, 18%);
  }
  100% {
    opacity: 0.3;
    transform: scale(1) translate(0%, 0%);
  }
}

@keyframes wanderingPulse4 {
  0% {
    opacity: 0.3;
    transform: scale(1) translate(0%, 0%);
  }
  20% {
    opacity: 0.5;
    transform: scale(1.1) translate(-15%, -10%);
  }
  40% {
    opacity: 0.4;
    transform: scale(1.05) translate(18%, -15%);
  }
  60% {
    opacity: 0.6;
    transform: scale(1.15) translate(15%, 12%);
  }
  80% {
    opacity: 0.4;
    transform: scale(1.1) translate(-12%, 15%);
  }
  100% {
    opacity: 0.3;
    transform: scale(1) translate(0%, 0%);
  }
}

/* Make animations more noticeable */
@keyframes blobMove {
  0% {
    transform: translate(-20%, -20%) scale(1) rotate(0deg);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  33% {
    transform: translate(20%, 20%) scale(1.3) rotate(120deg);
    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  }
  66% {
    transform: translate(-20%, 20%) scale(0.7) rotate(240deg);
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
  }
  100% {
    transform: translate(20%, -20%) scale(1.2) rotate(360deg);
    border-radius: 60% 40% 30% 70% / 40% 60% 40% 60%;
  }
}

@keyframes blobMove2 {
  0% {
    transform: translate(15%, 15%) scale(1.1) rotate(360deg);
    border-radius: 50% 50% 30% 70% / 30% 70% 70% 30%;
  }
  33% {
    transform: translate(-15%, -15%) scale(0.9) rotate(240deg);
    border-radius: 30% 70% 50% 50% / 70% 30% 30% 70%;
  }
  66% {
    transform: translate(15%, -15%) scale(1.3) rotate(120deg);
    border-radius: 70% 30% 70% 30% / 30% 70% 30% 70%;
  }
  100% {
    transform: translate(-15%, 15%) scale(1) rotate(0deg);
    border-radius: 30% 70% 70% 30% / 50% 50% 50% 50%;
  }
}

@keyframes blobMove3 {
  0% {
    transform: translate(0, 15%) scale(1.2) rotate(180deg);
    border-radius: 70% 30% 50% 50% / 30% 70% 50% 50%;
  }
  33% {
    transform: translate(-15%, -15%) scale(0.8) rotate(60deg);
    border-radius: 50% 50% 70% 30% / 30% 70% 30% 70%;
  }
  66% {
    transform: translate(15%, 0) scale(1.3) rotate(300deg);
    border-radius: 30% 70% 30% 70% / 70% 30% 70% 30%;
  }
  100% {
    transform: translate(-15%, 15%) scale(1.1) rotate(180deg);
    border-radius: 50% 50% 50% 50% / 30% 70% 70% 30%;
  }
}

@keyframes pulseOverlay {
  0% {
    opacity: 0.4;
    transform: scale(1) translate(0, 0);
  }
  25% {
    opacity: 0.6;
    transform: scale(1.1) translate(5%, 5%);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.15) translate(-5%, 2%);
  }
  75% {
    opacity: 0.6;
    transform: scale(1.1) translate(2%, -5%);
  }
  100% {
    opacity: 0.4;
    transform: scale(1) translate(0, 0);
  }
}

/* Add a subtle color flicker effect */
@keyframes colorFlicker {
  0%,
  100% {
    opacity: 0.95;
  }
  50% {
    opacity: 0.85;
  }
}

/* Add this to create occasional "energy bursts" */
.lavaBackground::before,
.lavaBackground::after,
.lavaBackground span {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Video Box Styles */
.videoBox {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.videoBox:hover {
  transform: scale(1.02);
  box-shadow: 0 0 30px rgba(var(--primary-main-rgb), 0.6) !important;
}

.videoBox video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.videoBox video.loaded {
  opacity: 1;
}
