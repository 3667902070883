.wand {
  width: 1px;
  height: 1px;
  /* background-image: url(/assets/images/wand/magic-wand.png); */
  position: absolute;
  left: 0%;
  top: 20%;
  translate: -50%;
  rotate: -3deg;
  z-index: 100;
  border-radius: 3vmin;
  overflow: hidden;
}

.tiles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flashing {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  border-radius: 3vmin;
}

.tile {
  position: relative;
  display: grid;
  place-items: center;
  /* width: 50vmin; */
  height: 33vh;
  aspect-ratio: 3/4;
  background-color: rgb(31, 41, 55);
  border-radius: 2vmin;
  box-shadow: 0 0 24px 6px rgb(0 0 0 / 50%);
  border: 1px solid rgb(0 0 0 / 50%);
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: box-shadow 0.3s ease;
  border-radius: 3vmin;
  overflow: hidden;
}
.tile img {
  box-shadow: inset 0 0 10px #000000;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.tile img.morphing {
  opacity: 0;
}

.tile:nth-child(1) {
  rotate: 3deg;
  z-index: 3;
}

.tile:nth-child(2) {
  rotate: -2deg;
  z-index: 2;
}

.tile:nth-child(3) {
  rotate: 5deg;
  z-index: 1;
}

.tile:nth-child(4) {
  rotate: -5deg;
  z-index: 1;
}

.tile:is(:nth-child(2), :nth-child(3), :nth-child(4)) {
  margin-left: -10vmin;
}

.tile > i {
  font-size: 15vmin;
  color: rgb(255 255 255 / 10%);
}

.tile > img {
  height: 100%;
  aspect-ratio: 1;
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: cover;
  opacity: var(--opacity);
  filter: blur(calc(var(--blur) * 20px));
}
